import app from "./init";

import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  getAuth,
  signOut,
} from "firebase/auth/cordova";

const auth = getAuth(app);

function logout() {
  return new Promise((reject, resolve) => {
    signOut(auth)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function googleSignIn() {
  return new Promise((resolve, reject) => {
    signInWithRedirect(auth, new GoogleAuthProvider())
      .then(() => {
        return getRedirectResult(auth);
      })
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        resolve({ user, token });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function facebookSignIn() {
  return new Promise((resolve, reject) => {
    const provider = new FacebookAuthProvider();

    signInWithRedirect(auth, provider)
      .then(() => {
        return getRedirectResult(auth);
      })
      .then((result) => {
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        resolve({ user, token });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// function facebookSignIn() {
//   return new Promise((resolve, reject) => {
//     const permissions = [
//       "public_profile",
//       "email",
//       "user_birthday",
//       "user_gender",
//       "user_location",
//     ];

//     window.facebookConnectPlugin.login(
//       permissions,
//       (resp) => {
//         console.log(resp);
//         const { accessToken } = resp.authResponse;
//         window.facebookConnectPlugin.api(
//           `me/?fields=id,email,birthday,public_profile,user_gender,user_location&access_token=${accessToken}`,
//           permissions,
//           (result) => {
//             console.log("Result: ", result);
//             resolve(result);
//           },
//           (error) => {
//             console.error("Failed: ", error);
//             reject(error);
//           }
//         );
//       },
//       (e) => {
//         console.log(e);
//         reject(e);
//       }
//     );
//   });
// }

export default {
  googleSignIn,
  facebookSignIn,
  logout,
};
